.faq {
      margin-top: 100px;
}

.heading {
      margin-bottom: 20px;

      font-size: 24px;
      font-weight: bold;
      
}

.item {
      position: relative;
      margin-bottom: 20px;
      box-sizing: border-box;

      width: 800px;
      padding-bottom: 15px;
      padding-right: 30px;

      line-height: 1.4;

      border-bottom: 1px solid rgba(0,0,0,.1);

      &_opened {
            .answer {
                  max-height: 100vh;
                  transform: scaleY(1);
            }

            .icon {
                  transform: rotate(-180deg);
            }
      }

      @media screen and (max-width: 992px) {
            width: 100%;
      }

      @media screen and (max-width: 450px) {
            width: 100%;
      }
}

.question {
      margin-bottom: 5px;

      cursor: pointer;
      
      font-size: 18px;
      font-weight: bold;
      color: rgb(0, 12, 26);
}

.answer {
      max-height: 0px;
      overflow: hidden;

      transition: all .5s ease-in-out;
}

.icon {
      position: absolute;
      right: 10px;

      cursor: pointer;

      height: 24px;
      width: 24px;

      transition: all .3s ease-in-out;
}